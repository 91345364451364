<template>
    <div :class="`wrapper  wrapper--${color} m-3 p-2 flex _column`">
        <h3 class="text-center">Inkoop budget mutaties: {{ bu }}</h3>
        <hr>
        <Loading v-if="loading" />
        <div v-else class="flex _row spaceevenly">
            <table :class="`table__border--${color}`">
                <thead :class="`table__head--${color}`">
                    <th>Inkoper</th>
                    <th>Verkocht</th>
                    <th>Budget</th>
                    <th>Aantal S</th>
                    <th>Voorraad waarde</th>
                    <th>Tov budget</th>
                    <th>Aantal P</th>
                    <th></th>
                    <th>Aantal > 120</th>
                    <th>% aantal > 120</th>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in mutations" :key="key">
                        <td>{{ item.naam }}</td>
                        <td class="table__data--right">{{ item.aantal }}</td>
                        <td class="table__data--right">{{ item.budget }}</td>
                        <td class="table__data--right green" v-if="item.stockaantal < '0'">{{ item.stockaantal }}</td>
                        <td class="table__data--right" v-else-if="item.stockaantal == '0'">{{ item.stockaantal }}</td>
                        <td class="table__data--right red" v-else>+ {{ item.stockaantal }}</td>
                        <td class="table__data--right green" v-if="item.voorraad < '0'">€ {{ item.voorraad }}</td>
                        <td class="table__data--right" v-else-if="item.voorraad == '0'">€ {{ item.voorraad }}</td>
                        <td class="table__data--right red" v-else>€ +{{ item.voorraad }}</td>
                        <td class="table__data--right green" v-if="item.tovbudget > '0'">
                            + {{ item.tovbudget }}
                        </td>
                        <td class="table__data--right green" v-else-if="item.tovbudget == '0'">
                            {{ item.tovbudget }}
                        </td>
                        <td class="table__data--right red" v-else>{{ item.tovbudget }}</td>
                        <td class="table__data--right red" v-if="item.purchasedaantal > '0'">+ {{ item.purchasedaantal
                            }}
                        </td>
                        <td class="table__data--right"
                            v-else-if="item.purchasedaantal == '0' || item.purchasedaantal == null">0</td>
                        <td class="table__data--right" v-else>{{ item.purchasedaantal }}</td>
                        <td class="table__data--clear"></td>
                        <td class="table__data--right red" v-if="item.langstaand > '0'">+ {{ item.langstaand }}</td>
                        <td class="table__data--right red" v-else-if="item.langstaand == '0'">{{ item.langstaand }}</td>
                        <td class="table__data--right red green" v-else>{{ item.langstaand }}</td>
                        <td class="table__data--right red" v-if="item.langstaandperc > '0'"> +{{ item.langstaandperc }}%
                        </td>
                        <td class="table__data--right green" v-else>{{ item.langstaandperc }}%</td>
                    </tr>
                </tbody>
                <tfoot>
                    <th></th>
                    <th class="table__data--right">{{ mutations_sum.aantal }}</th>
                    <th class="table__data--right">{{ mutations_sum.budget }}</th>
                    <th class="table__data--right green" v-if="mutations_sum.stockaantal < '0'">{{
        mutations_sum.stockaantal
    }}</th>
                    <th class="table__data--right" v-else-if="mutations_sum.stockaantal == '0'">{{
        mutations_sum.stockaantal
    }}</th>
                    <th class="table__data--right red" v-else>+ {{ mutations_sum.stockaantal }}</th>
                    <th class="table__data--right green" v-if="mutations_sum.voorraad < '0'">€ {{ mutations_sum.voorraad
                        }}
                    </th>
                    <th class="table__data--right" v-else-if="mutations_sum.voorraad == '0'">€ {{ mutations_sum.voorraad
                        }}
                    </th>
                    <th class="table__data--right red" v-else>€ +{{ mutations_sum.voorraad }}</th>
                    <th class="table__data--right green" v-if="mutations_sum.tovbudget > '0'">
                        + {{ mutations_sum.tovbudget }}
                    </th>
                    <th class="table__data--right green" v-else-if="mutations_sum.tovbudget = '0'">
                        {{ mutations_sum.tovbudget }}
                    </th>
                    <th class="table__data--right red" v-else>- {{ mutations_sum.tovbudget }}</th>
                    <th class="table__data--right red" v-if="mutations_sum.purchasedaantal > '0'">+ {{
        mutations_sum.purchasedaantal }}</th>
                    <th class="table__data--right"
                        v-else-if="mutations_sum.purchasedaantal == '0' || mutations_sum.purchasedaantal == null">0</th>
                    <th class="table__data--right green" v-else>{{ mutations_sum.purchasedaantal }}</th>
                    <th class="table__data--clear"></th>
                    <th class="table__data--right red" v-if="mutations_sum.langstaand > '0'">+ {{
        mutations_sum.langstaand }}</th>
                    <th class="table__data--right" v-else-if="mutations_sum.langstaand == '0'">{{
        mutations_sum.langstaand }}</th>
                    <th class="table__data--right green" v-else>{{ mutations_sum.langstaand }}</th>
                    <th class="table__data--right"></th>
                </tfoot>
            </table>
        </div>
        <div v-if="!loading" class="flex _row spaceevenly m-2">
            <div v-if="bu == 'trucks'">
                <h3 class="text-center">Huidge budget</h3>
                <newBudgetTable :table_data="budget_trucks.budget" :table_sum="budget_trucks.budget_sum"
                    :table_style="color" />
            </div>
            <div v-else-if="bu == 'trailers'">
                <h3 class="text-center">Huidge budget</h3>
                <newBudgetTable :table_data="budget_trailers.budget" :table_sum="budget_trailers.budget_sum"
                    :table_style="color" />
            </div>
            <div v-else-if="bu == 'vans'">
                <h3 class="text-center">Huidge budget</h3>
                <newBudgetTable :table_data="budget_vans.budget" :table_sum="budget_vans.budget_sum"
                    :table_style="color" />
            </div>
        </div>
    </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import newBudgetTable from "@/components/newBudgetTable.vue";

export default {
    components: { Loading, newBudgetTable },
    props: ["bu"],
    data: () => ({
        loading: null,
        mutations: null,
        mutations_sum: null,
        budget_trucks: null,
        budget_trailers: null,
        budget_vans: null,
        name: null,
        color: null,
    }),
    created() {
        this.getData(this.bu);
    },
    watch: {
        bu(newbu) {
            this.getData(newbu);
        },
    },
    methods: {
        getData(bu) {
            this.loading = true;
            (this.bu == "vans") ? this.color = "210" : this.color = "200";
            (bu == "vans") ? this.numeric_bu = "210" : this.numeric_bu = "200";
            request(
                `purchaseduo/budgetbreakdown-mutations/${bu}`,
                "GET"
            ).then(({ mutations, mutations_sum }) => {
                this.mutations = mutations;

                this.mutations_sum = mutations_sum;
            });

            request(`budget/${this.numeric_bu}`, "GET").then(
                ({
                    budget_trucks,
                    budget_trailers,
                    budget_trucks_total,
                    budget_vans,
                    budget_omlooptijd_vans,
                    budget_omlooptijd_trucks,
                }) => {
                    this.budget_trucks = budget_trucks;
                    this.budget_vans = budget_vans;
                    this.budget_trailers = budget_trailers;
                    this.budget_trucks_total = budget_trucks_total;
                    this.budget_omlooptijd_vans = budget_omlooptijd_vans;
                    this.budget_omlooptijd_trucks = budget_omlooptijd_trucks;
                    this.loading = false;
                }
            );
        },
    },
};
</script>