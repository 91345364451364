<template>
  <table :class="`table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <th>Inkoper</th>
      <th>Verkocht</th>
      <th>%</th>
      <th>Budget</th>
      <th>Aantal S</th>
      <th>Voorraad waarde</th>
      <th>Tov budget</th>
      <th>Aantal P</th>
      <th></th>
      <th>Aantal > 120</th>
      <th>% aantal > 120</th>
    </thead>
    <tbody>
      <tr v-for="(item, key) in table_data" :key="key">
        <td>{{ item.naam }}</td>
        <td class="table__data--right">{{ item.aantal }}</td>
        <td class="table__data--right">{{ item.percentage }}%</td>
        <td class="table__data--right">{{ item.budget }}</td>
        <td class="table__data--right">{{ item.stockaantal }}</td>
        <td class="table__data--right">€ {{ item.voorraad }}</td>
        <td class="table__data--right green" v-if="item.tovbudget > '-1'">
          {{ item.tovbudget }}
        </td>
        <td class="table__data--right red" v-else>{{ item.tovbudget }}</td>
        <td class="table__data--right" v-if="item.purchasedaantal != null">{{ item.purchasedaantal }}</td>
        <td class="table__data--right" v-else>0</td>
        <td class="table__data--clear"></td>
        <td class="table__data--right">{{ item.langstaand }}</td>
        <td class="table__data--right red" v-if="item.langstaandperc > '25'">
          {{ item.langstaandperc }}%
        </td>
        <td class="table__data--right" v-else>{{ item.langstaandperc }}%</td>
      </tr>
    </tbody>
    <tfoot>
      <th></th>
      <th class="table__data--right">{{ table_sum.aantal }}</th>
      <th class="table__data--right">{{ table_sum.percent }}</th>
      <th class="table__data--right">{{ table_sum.budget }}</th>
      <th class="table__data--right">{{ table_sum.stockaantal }}</th>
      <th class="table__data--right">€ {{ table_sum.voorraad }}</th>
      <th class="table__data--right">{{ table_sum.tovbudget }}</th>
      <th class="table__data--right">{{ table_sum.purchasedaantal }}</th>
      <th class="table__data--clear"></th>
      <th class="table__data--right">{{ table_sum.langstaand }}</th>
      <th class="table__data--right">{{ table_sum.langstaandperc }}%</th>
    </tfoot>
  </table>
</template>

<script>
export default {
  props: {
    table_data: [Object, Array],
    table_style: [String, Number],
    table_sum: [Object, Array],
  },
};
</script>
<style>
.table__data--clear{
    background-color: white !important;
}
</style>
